.PricingPageMain {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #e5f3f9;
}

.PrimaryTitle {
  color: #008ac1;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
}
.PrimaryTitleLower {
  color: #008ac1;
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1rem;
}
.InforContainer {
  align-self: center;
  padding: 1.5rem;
}

.SecondaryTitle {
  color: #000000;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
}

.PricingGrid {
  display: grid;
  grid-template-columns: 7fr 0.1fr 7fr 0.1fr 7fr 0.1fr 7fr 0.1fr 7fr;
  grid-template-rows: auto;
  grid-gap: 0.1rem;
  overflow-x: auto;
  background-color: #fbfbfb;
  border: 5px solid #bcdfed;
  border-radius: 15px;
}
.InnerPricingGird {
  display: grid;
  grid-template-rows: 4fr 3fr 4.5fr;
  grid-gap: 0.5rem;
  background-color: #fbfbfb;
}
.InnerPricingGird:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.InnerGridTitle {
  color: #000000;
  font-size: 26px;
  font-weight: bold;
  padding: 2rem 1rem;
  text-align: center;
  justify-items: center;
}
.InnerGridText {
  padding-top: 1rem;
  color: #8f8f8f;
  font-size: 15px;
  text-align: center;
}
.InnerGridPrice {
  display: grid;
  grid-template-rows: auto;
  padding: 1rem;
}
.PricingGridContainer {
  padding: 2rem;
}
.PriceDetail {
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}
.Price {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  padding: 1rem 1rem 0rem 1rem;
  text-align: center;
}
.verticalLine {
  border-left: 5px solid #bcdfed;
  height: 100%;
}

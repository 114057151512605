.carousel {
  height: inherit;
  margin: 0;
  width: inherit;
}

.image-slide {
  height: 100%;
  width: 100%;
  transition: background-image 0.3s ease-in-out;
}

.slide-arrow {
  color: #14B6D4;
  cursor: pointer;
  font-size: 2rem;
  position: fixed;
  top: 50%;
}

.slide-arrow.right {
  right: 1rem;
}

.slide-arrow.left {
  left: 1rem;
}
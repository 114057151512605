.MemberCard {
  width: 190px;
}

.MemberImageDiv {
  margin: 0;
  width: 100%;
  height: 190px;
  background-color: #c4c4c4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.CardImageDiv:hover {
  border: 1px solid #008ac1;
}

.BottomContainer {
  padding: 10px;
  height: 80px;
  background-color: #f1f1f1;
}

.MemberCardName {
  width: 100%;
  font-size: 20px;
  padding-top: 5px;
  text-align: center;
  font-weight: bold;
}
.MemberCardDesc {
  width: 100%;
  font-size: 16px;
  color: #000000;
  padding-top: 5px;
  text-align: center;
}

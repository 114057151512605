header.NewHeader {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  /* height: 5rem; */
  padding: 0.6rem 3rem;
}

.HeaderLink {
  display: grid;
  grid-auto-flow: column;
}

.HeaderLink a { 
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
}

.HeaderLink a:hover {
  background-color: #f7b21f;
}

.HeaderLink .active {
  background-color: #f7b21f;
}
.LogoLink:focus {
  outline: none;
}
.LogoLink::-moz-focus-inner {
  border: 0;
}

.OnHead {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}

.OnHead:hover .UserNames {
  background-color: #f7b21f;
  color: #000000;
}

.OnHead:hover svg {
  fill: #000;
}

div.DropDownGrid {
  display: flex;
  flex-direction: column;
}
.HeaderLinksWrap {
  display: flex;
}

div.HeaderLinksWrap.LoggedIn .BelowHeader {
  background-color: #f9f9f9;
  position: absolute;
  top: 100%;
  width: 10rem;
  right: 0;
}

.DropDownArrowSvg {
  width: 2rem;
  height: 2rem;
  fill: #000000;
}

.UserName {
  font-weight: bold;
  color: #000;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.DropDownContent {
  color: #000;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  z-index: 1;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.DropDownLink {
  cursor: pointer;
  padding: 1rem 1.5rem;
}

.DropDownLink:hover,
.DropDownLink:active {
  background-color: #f1f1f1;
}

.DropDownLink,
.DropDownLink:visited {
  color: #000;
  text-decoration: none;
}
 .HeaderLinkBack:hover { 
  background-color: #f7b21f;
}

a.HeaderLinkDocs {
  color: #000;
  border-radius: 10px;
}
a.HeaderLinkDocs:hover {
  background-color: var(--secondary-color);
 
}
@media only screen and (max-width: 640px) {
  .HeaderLinkPricing,
  .HeaderLinkDocs {
    display: none;
  }
}

.Secondary-Btn {
  background-color: white;
  width: 9.7rem;
  height: 2.9rem;
  font-size: 1rem;
  /* font-weight: bold; */
  border: 1px solid white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 5px;
  /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
}

.Secondary-Btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.sidebar-nav {
  padding: 0 3rem;
}

.sidebar-nav-menu {
  padding: 2rem 0;
}
.div-item {
  display: block;
}
.div-item-body {
  /*This is equivalent to display: none*/
  overflow: hidden;
  height: 0;
  opacity: 0;
  padding: 0 2rem 5px 1rem;
  line-height: 1.3rem;
  font-size: 17px;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}
.div-item.item-active .div-item-body {
  /*This is equivalent to display: block*/
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

.div-item-head-help {
  /*To move button in header to right*/
  position: absolute;
  right: 35px;
  cursor: pointer;
}

.div-item-head {
  /*To style header*/
  background-color: #008ac1;
  color: #fff;
  margin-bottom: 5px;
  padding: 10px 1rem;
  position: relative;
  cursor: pointer;
}

.Header {
  background-color: #008ac1;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  padding: 0 3rem;
  position: relative;
}
.HeaderLinks {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
}
.HeaderLinks a {
  font-size: 0.9em;
  color: #fff;
}
.HeaderLinkLogin {
  border: 2px solid #fff;
  padding: 1rem 3rem;
  font-size: 0.9em;
  color: #fff;
}
.TurnLight:hover {
  transition: 0.4s;
  background-color: #f1f1f1;
  color: #000 !important;
}
/* remove focus from logo link - mozilla */
.LogoLink:focus {
  outline: none;
}
.LogoLink::-moz-focus-inner {
  border: 0;
}

.HeaderLinksWrap {
  display: flex;
}

.OnHeader {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
  position: relative;
}

.OnHeader:hover .UserNames {
  color: #f7b21f;
}
.OnHeader:hover svg {
  fill: #f7b21f;
}

.BelowHeader {
  background-color: #f9f9f9;
  position: absolute;
  top: 100%;
  width: 10rem;
  right: 0;
}
.DropdownArrowSvg {
  width: 2rem;
  height: 2rem;
  fill: white;
}
.UserNames {
  font-weight: bold;
  color: #fff;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.DropDownContent {
  color: #000;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  z-index: 1;
  box-shadow: 0 4px 4px 0 rgba(117, 117, 117, 0.2);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.DropDownLink {
  cursor: pointer;
  padding: 1rem 1.5rem;
}
.DropDownLink:hover,
.DropDownLink:active {
  background-color: #f1f1f1;
}
.DropDownLink,
.DropDownLink:visited {
  color: #000;
  text-decoration: none;
}
a.HeaderLinkBackToConsole {
  text-transform: uppercase;
  border: 2px solid #ffffff;
  padding: 1rem 3rem; /* margin-left: 2rem; */
  font-size: 0.9em;
  color: white;
  padding: 1rem;
}
.StripBorder {
  text-transform: uppercase;
  padding: 1rem 3rem;
  font-size: 0.9em;
  color: white;
  padding: 1rem;
}
@media only screen and (max-width: 640px) {
  .HeaderLinkPricing,
  .HeaderLinkDocs {
    display: none;
  }
}

.ContactPageMain {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #e5f3f9;
}

.PrimaryTitle {
  color: var(--primary-color);
  font-size: 44px;
  font-weight: bold;
  text-align: center;
}

.SecondaryTitle {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.InformationContainer {
  align-self: center;
  padding: 1.5rem;
}

.ContactGrid {
  display: grid;
  grid-template-columns: 3fr 3fr;
  grid-template-rows: 3;
  grid-gap: 0.1rem;
  overflow-x: auto;
}
.InnerContactGird {
  width: 90%;
  max-width: 1240px;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  display: flex;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  background-color: #fbfbfb;
}
.InnerContactGird:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.InnerGridTitle {
  color: #000000;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  justify-items: center;
}

.InnerGridTitleText {
  color: #000000;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  justify-items: center;
}

.InnerLocationTitleText {
  color: #000000;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  justify-items: center;
}

.InnerGridContact {
  display: grid;
  grid-template-rows: auto;
  padding: 1rem;
  gap: 2rem;
}

.InnerGridContac {
  width: 60%;
  padding: 11% 0;
}
.ContactGridContainer {
  padding: 2rem;
}
.Contact {
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}
.ContactName {
  color: #000000;
  font-size: 20px;
  padding: 0rem 1rem 0rem 1rem;
  text-align: center;
  font-weight: 600;
}

.TelephoneSmall,
.MailSmall,
.ThumbSmall,
.LocationSmall,
.MapSmall {
  width: 3rem;
  height: 3rem;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 1rem;
}

.Socials {
  align-items: center;
  padding: 0rem 1rem 0rem 2rem;
}

.LocationGrid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  grid-gap: 0.1rem;
  overflow-x: auto;
  background-color: #fbfbfb;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  width: 97%;
}

.InnerLocationGird {
  display: grid;
  grid-template-rows: 4fr;
  grid-gap: 0.5rem;
  background-color: #fbfbfb;
}

.InnerAddressGrid {
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  gap: 7%;
  padding: 0 2rem;
}

.InnerLocationGird:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.ContactPage {
  height: 100vh;
}

.InnerGridTitle {
  color: #000000;
  font-size: 26px;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  display: grid;
  gap: 2rem;
  justify-items: center;
}

.InnerGridText {
  padding-top: 1rem;
  color: #8f8f8f;
  font-size: 15px;
  text-align: center;
}

.LocationGridContainer {
  padding: 2rem;
}

.verticalLine {
  border-left: 1px solid var(--primary-color);
  height: 100%;
}

.MapGrid {
  text-align: center;
}

@font-face {
  font-family: proxima-nova;
  src: url("./assets/fonts/proxima-nova-regular.otf") format("truetype");
}

@font-face {
  font-family: proxima-nova;
  font-weight: 100;
  src: url("./assets/fonts/proxima-nova-thin.otf") format("truetype");
}

@font-face {
  font-family: proxima-nova;
  font-weight: 600;
  src: url("./assets/fonts/proxima-nova-bold.otf") format("truetype");
}

@font-face {
  font-family: monaco;
  src: url("./assets/fonts/monaco.ttf") format("truetype");
}

html {
  --primary-color: #008ac1;
  --secondary-color: #f7b21f;
  --secondary-light-color: #ffcc5f;
  --gray-color: #555;
  --gray-light-color: #ccc;
  --primary-dark-color: #06222f;
  --primary-light-color: #b6e3f9;
  --gray-bg-light-color: #e5ebf3;
  --blue-light-color: #99d2e9;
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
}

#root > div {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--gray-bg-light-color);
}

textarea,
input,
select,
button {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
}

textarea:focus,
input:focus,
select:focus,
button:focus {
  outline: none;
}

.uppercase {
  text-transform: uppercase;
}

.tiny {
  font-weight: 200;
}

.bold {
  font-weight: 500;
}

.extra-bold {
  font-weight: 900;
}

a {
  text-decoration: none;
}

textarea,
input,
select,
button {
  font-family: inherit;
  font-size: inherit;
  border-radius: 0;
  margin: 0;
}

textarea:focus,
input:focus,
select:focus,
button:focus {
  outline: none;
}

img {
  display: block;
}

.Terms p {
  padding-bottom: 1rem;
}

.Terms {
  padding: 2rem;
  font-size: 1rem;
  line-height: 1.5;
}

.FooterRow {
  padding: 3rem;
}

.ImageDivACN {
  background-image: url("./assets/images/article1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  width: -webkit-fill-available;
  margin: 0 auto;
  /* https://tenor.com/view/web-design-modern-web-gif-gif-15349911 */
}

.cloudNative {
  text-align: center;
}

.InformationBar {
  background: #f8f7e5;
  height: 5rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
}

.InfoHeader {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.componentBackdrop {
  background: #f1f1f1;
  min-height: 100vh;
}

.FullWidth {
  width: 100%;
}

.Terms {
  padding: 2rem;
  line-height: 18px;
}

.SectionTitle {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--gray-light-color);
}

.SubTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0;
}
.SectionSubTitle {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0;
}
.SubText {
  font-size: 0.85rem;
  font-weight: 400;
  color: var(--primary-dark-color);
  margin: 0;
  padding: 0;
}

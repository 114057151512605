.ErrorPageConent {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorPageDigit {
  color: #f7b21f;
  font-size: 12.5rem;
  line-height: 14rem;
}

.ErrorPageText {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  text-align: center;
}

.ErrorPageTextFirst {
  font-size: 2rem;
  line-height: 2.344rem;
}

.ErrorPageTextSecond {
  font-weight: 400;
  line-height: 1.172rem;
}

.ErrorPageButton {
  display: flex;
  justify-content: center;
}

.Primary-Btn {
  background-color: #f7b21f;
  width: 9.7rem;
  height: 2.9rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  border: none;
  outline: 2px solid #f7b21f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Primary-Btn:hover {
  background-color: transparent;
  color: #f7b21f;
  outline: 2px solid #f7b21f;
}
